.all {
  display: flex;
  justify-content: center;
  margin: 50px 0px;
}

.container {
  background-color: rgba(68, 64, 64, 0.9);
  color: white;
  /* opacity: 0.9; */
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 20px;
  box-shadow: 0 0px 30px 0px rgba(255, 255, 255, 0.9);
}

.text {
  color: red;
  margin-right: 50px;
}
