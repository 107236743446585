.style {
  color: black;
  width: 120px;
  height: 50px;
  font-family: 'acme';
  font-size: 1.2em;
  background-color: #615d5d;
  position: absolute;
  left: 10%;
}

.style:hover {
  cursor: pointer;
  background-color: #918c8d;
}
