.container {
  width: 100%;
}

.text {
  font-weight: bold;
}

.textContact {
  font-weight: bold;
  text-align: center;
  font-size: 1.5em;
}
