.style {
  text-align: center;
  flex: 1;
}

.style:hover {
  cursor: pointer;
}

.style:hover,
.selected {
  background-color: #918c8d;
}

.unselected {
  background-color: #615d5d;
}

.text {
  color: black;
  font-size: 1.2em;
}
