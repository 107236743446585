.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  width: 200px;
  border-radius: 30px;
}

.link {
  text-decoration: none;
  color: #615d5d;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ccc4c5;
}

.link:hover {
  text-shadow: 0 0 30px whitesmoke;
}

.image:hover {
  /*box-shadow: 0 0px 20px 0px rgba(255, 255, 255, 0.9);*/
  animation: shake 0.5s;
  animation-iteration-count: initial;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
