.mainContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  font-family: 'acme';
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-left: 20px;
}

.header {
  font-size: 3em;
  margin-top: 0px;
}

.line {
  height: 10px;
  width: 100%;
  margin-top: 20px;
  background-color: #918c8d;
  border-radius: 5px;
  box-shadow: 0 0px 20px 0px rgba(255, 255, 255, 0.9);
}

.slectorContainer {
  display: flex;
  width: 100%;
}

.image {
  width: 150px;
  height: 150px;
  border-radius: 20px;
}
