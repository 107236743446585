.container {
  display: flex;
  justify-content: right;
  margin-right: 20px;
  margin-top: 20px;
}

.navUl {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.navLi {
  float: left;
  font-family: 'acme';
  font-size: 1.5em;
}

.navLiA {
  display: block;
  /* background-color: brown; */
  margin: 1px;
  padding: 20px;
  color: #615d5d;
  text-decoration: none;
  text-align: center;
}

.navLiA:hover,
.active {
  text-shadow: 0 0 30px whitesmoke;
}
