.portrait {
  height: 150px;
}

.landscape {
  width: 150px;
}

.main {
  padding: 0px 10px;
  margin-top: 20px;
}
