.container {
  background-color: #918c8d;
  display: flex;
  /* width: 100%; */
  height: 150px;
  justify-content: space-between;
  padding: 0px 20px;
  margin-top: 20px;
  border-radius: 20px;
  align-items: center;
}

.image {
  width: 75%;
  height: 75%;
  border-radius: 10px;
}

.link {
  width: 100px;
  background-color: aqua;
}

.qr {
  border-radius: 10px;
  height: 85%;
}
